html {
  font-family: 'Inter', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0F172A;
  color: #FFCAEC;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
