.main {
display: flex;
  padding: 15% 15% 0% 15%;
}

@media screen and (min-width: 1211px) {
  .main {
    gap: 5rem;
  }
}

@media screen and (max-width: 1210px) {
  .main {
    flex-direction: column;
  }
}

.main h1 {
  font-weight: 600;
  font-size: 2.5rem;
}

@media (hover: hover) {
  .main h1:hover {
    opacity: 80%;
    cursor: pointer;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ninja {
  height: 7rem;
}

.ninja img {
  height: 100%;
  border-radius: 100%;
}

@media (hover: hover) {
  .ninja img:hover {
    opacity: 80%;
    cursor: pointer;
  }
}

.rocket {
  margin: 1rem;
  padding: .5rem;
  width: 2rem;
  border-radius: 100%;
  background-color: #FFCAEC;
}

.rocket img {
  height: 90%;
  width: 90%;
  display: block;
  margin: auto;
}

@media (hover: hover) {
  .rocket:hover {
    opacity: 80%;
    cursor: pointer;
  }
}

.spin {
  animation: spin 1s;
  opacity: 100% !important;
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.text p {
  font-weight: 500;
  font-size: 1.25rem;
}

.credera {
  height: 1rem;
  background-color: #FFCAEC;
  padding: .5%;
  border-radius: 5%;
  margin: 0 .45rem;
  display: inline-block;
}

@media (hover: hover) {
  .credera:hover {
    opacity: 80%
  }
}

.credera img {
  height: 100%;
  width: auto;
  display: block;
  margin: auto;
}

.socials {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
