.credit {
  float: right;
  margin-right: 1rem;
  display: block;
  font-size: smaller;
  opacity: 75%;
}

.credit a {
  color: #FFCAEC;
  padding-right: .25rem;
}

@media (hover: hover) {
  .credit a:hover {
    opacity: 75%;
  }
}
